.container,
.card,
.card_front,
.card_back {
  width: 100%;
  max-width: 700px;
  height: 300px;
}

.card_front,
.card_back {
  height: 100%;
  box-shadow: 0 5px 25px 0 rgba(51, 51, 51, 0.28);
  background-size: cover;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.card-symbol {
  margin: 0;
  width: 100%;
  text-align: center;
  color: floralwhite;
  font-family: 'Prompt', sans-serif;
  font-size: 24px;
}

.card_front::before {
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(23, 20, 53, 0.55)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(23, 20, 53, 0.55) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.card_front span {
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.card_front span:first-of-type {
  padding: 0.5em 0 0;
  border-top: 1px solid wheat;
  border-color: #ff4081;
  width: 75%;
  color: white;
}

.card_front {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  transform: rotateY(0deg);
  flex-direction: column;
  padding: 2em;
}

.card_front .card-symbol {
  margin: 0 0.15em;
  font-size: auto;
}

.card_back {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transform: rotateY(-180deg);
}

div.container * {
  box-sizing: border-box;
}

div.container *,
div.container *::before,
div.container *::after {
  box-sizing: border-box;
}

.card-text {
  padding: 0 4.5em 1em;
  line-height: 1.4;
}

.card-text p:first-of-type {
  margin-top: 0;
  padding: 1.5em 0 0;
  border-top: 8px solid #ff4081;
}

.container {
  perspective: 1000px;
  padding: 2em;
  opacity: 0;
  animation-name: scale-up;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}

@keyframes scale-up {
  0% {
    opacity: 0.2;
    transform: scale(0);
  }

  60% {
    opacity: 0.7;
    transform: scale(110%);
  }

  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

.card {
  transition: 0.6s;
  transition-delay: 0.4s;
  transform-style: preserve-3d;
  position: relative;
}

.container:hover .card,
.container.hover .card {
  transform: rotateY(-180deg);
}
