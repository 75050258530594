.popup-header {
  text-transform: uppercase;
  line-height: 1.5em;
  color: #bbb;
}

.popup-header::after {
  content: "\a";
  white-space: pre;
}

.info-popup {
  min-width: 300px;
}

.info-popup .content-container {
  padding: 1em;
  overflow: auto;
}

.info-popup .content-container:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.popup-content {
  font-size: 1.2em;
  font-weight: 100;
  text-indent: 3em;
}

.leaflet-popup-content-wrapper {
  box-shadow: none;
}
