:not(.Mui-selected) > .MuiTab-wrapper {
  color: #ccc;
}

:not(.Mui-selected) > .MuiTab-wrapper:hover {
  color: '#40a9ff';
}

.tabs-root {
  width: 100%;
  box-sizing: border-box;
}
