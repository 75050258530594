.stats-page-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

.chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: white;
  opacity: 0;
  animation-name: move-in;
  animation-duration: 1.6s;
  animation-fill-mode: forwards;
}

@media only screen and (min-width: 600px) {
  .chart-container {
    width: 500px;
  }
}

.chart-container span.chart-title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5em;
  border-bottom: 3px solid #ff4081;
}

.delay-0 {
  animation-delay: 0;
}

.delay-1 {
  animation-delay: 0.2s;
}

@keyframes move-in {
  0% {
    opacity: 0.2;
    transform: translateY(1000%);
  }

  60% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
