.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
  transition: opacity 0.6s;
}

.info-line {
  padding: 0.5em;
}

.invisible-legend > button {
  opacity: 0.5;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.legend img {
  transform: translateY(25%);
  padding-right: 1em;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.unstyled-button {
  border: none;
  background: none;
  outline-style: none;
}
