#navigation-menu-content-container li {
  transition: all 0.6s;
}

#navigation-menu-content-container li:hover {
  background-color: #4b4b7d;
}

.drawer-item {
  opacity: 0;
  animation-name: move-in;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}

.delay-0 {
  animation-delay: 0;
}

.delay-1 {
  animation-delay: 0.2s;
}

.delay-2 {
  animation-delay: 0.4s;
}

.delay-3 {
  animation-delay: 0.6s;
}

.delay-4 {
  animation-delay: 0.8s;
}

.delay-5 {
  animation-delay: 1s;
}

.delay-6 {
  animation-delay: 1.2s;
}

@keyframes move-in {
  0% {
    opacity: 0.2;
    transform: translateX(-50px);
  }

  60% {
    opacity: 0.7;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
