button {
  cursor: pointer;
}

.leaflet-popup-content {
  width: auto !important;
}

.leaflet-bar button,
.leaflet-bar button:hover {
  background-color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.btn {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(1);
  background-color: white;
  padding: 0.25em;
  border-radius: 12px;
}

.btn:hover {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(1.25);
}
